import { makeAutoObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { EmployeeFormData } from 'mobx/storeTypes';
import { employeeFormAPI } from 'api/employeeForm';

export class EmployeeFormStore {
  public rootStore: RootStore;

  public totalFormData: EmployeeFormData.TotalForm | null = null;
  public isDataLoading = false;
  public reviewPeriods: EmployeeFormData.ReviewPeriod[] | null = null;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  public async fetchTotalData(employeeId: number, reviewPeriodId: number) {
    try {
      this._setIsDataLoading(true);

      const res = await employeeFormAPI.getTotalForm(
        employeeId,
        reviewPeriodId
      );

      this._setTotalFormData(res);
      this._setIsDataLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  private _setTotalFormData(data: EmployeeFormData.TotalForm) {
    this.totalFormData = data;
  }

  private _setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public async fetchReviewPeriods(employeeId: number) {
    try {
      const res = await employeeFormAPI.getReviewPeriods(employeeId);

      this._setReviewPeriods(res);
    } catch (e) {
      console.error(e);
    }
  }

  private _setReviewPeriods(data: EmployeeFormData.ReviewPeriod[]) {
    this.reviewPeriods = data;
  }

  get employeeData() {
    return this.totalFormData?.employee;
  }

  get marksAnalysis() {
    return this.totalFormData?.marks_analysis;
  }

  get responsibilities() {
    return this.totalFormData?.form.responsibilities;
  }

  get formProjects() {
    return this.totalFormData?.form.projects;
  }

  get achievements() {
    return this.totalFormData?.form.achievements;
  }

  get fails() {
    return this.totalFormData?.form.fails;
  }

  get intersectionsData() {
    return this.totalFormData?.crossing_coworkers.coworkers;
  }

  get selfWordsList() {
    return this.totalFormData?.most_usage_words.self_words;
  }

  get coworkersWordsList() {
    return this.totalFormData?.most_usage_words.coworkers_words;
  }

  get advicesList() {
    return this.totalFormData?.respondents;
  }

  get projectsInfo() {
    return this.totalFormData?.projects.projects;
  }
}
