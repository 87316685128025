import { useMemo } from 'react';
import { ColumnChartAxisParams } from './columnChartTypes';
import { useTheme } from '@mui/material';

export type PlotOptionsParams = {
  handleClick?: (e: Highcharts.SeriesClickEventObject) => void;
  cursor?: string;
};

export type TooltipOptionsParams = {
  formatter?: Highcharts.TooltipFormatterCallbackFunction;
  outside?: boolean;
  padding?: number;
};

export const useConstructAxisOptions = ({
  xAxisCategories,
  isScrollEnabled,
  plotBands,
}: ColumnChartAxisParams) => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      xAxis: [
        {
          lineColor: theme.palette.custom.fontColorNotActive,
          labels: {
            enabled: false,
          },
          plotBands: plotBands,
          ...(isScrollEnabled && {
            min: 0,
            max: 5,
            scrollbar: {
              enabled: true,
              barBackgroundColor: theme.palette.custom.fontColorNotActive,
              barBorderRadius: 4,
              barBorderWidth: 0,
              buttonArrowColor: 'none',
              buttonBackgroundColor: 'none',
              buttonBorderWidth: 0,
              trackBackgroundColor: theme.palette.custom.paperBgColor,
              trackBorderWidth: 0,
              trackBorderRadius: 4,
              trackBorderColor: theme.palette.customBackgrounds.elevation5,
              height: 6,
            },
          }),
        },
        {
          opposite: true,
          linkedTo: 0,
          lineColor: theme.palette.custom.fontColorNotActive,
          labels: {
            style: {
              color: theme.palette.custom.fontColor,
            },
          },
          categories: xAxisCategories,
        },
      ],
      yAxis: [
        {
          lineColor: theme.palette.custom.fontColorNotActive,
          gridLineColor: theme.palette.custom.fontColorNotActive,
          lineWidth: 1,
          title: {
            text: undefined,
          },
          labels: {
            enabled: true,
            style: {
              color: theme.palette.custom.fontColorNotActive,
            },
          },
        },
        {
          lineColor: theme.palette.custom.fontColorNotActive,
          opposite: true,
          lineWidth: 1,
          title: {
            text: undefined,
          },
        },
      ],
    };
  }, [xAxisCategories, plotBands, theme, isScrollEnabled]);
};

export const useConstructPlotOptions = ({
  handleClick,
  cursor,
}: PlotOptionsParams) => {
  return useMemo(() => {
    return {
      column: {
        maxPointWidth: 35,
        pointPadding: 0,
        borderColor: 'none',
        events: {
          click: handleClick,
        },
      },
      series: {
        cursor: cursor,
      },
    };
  }, [handleClick, cursor]);
};

export const useConstructTooltipOptions = ({
  formatter,
  outside,
  padding,
}: TooltipOptionsParams) => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      backgroundColor: theme.palette.background.default,
      outside: outside,
      followPointer: true,
      style: {
        color: theme.palette.custom.fontColor,
        width: 520,
      },
      padding: padding,
      useHTML: true,
      formatter: formatter,
    };
  }, [formatter, outside, padding, theme]);
};
