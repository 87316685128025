import { forEach } from 'lodash-es';
import {
  ColumnChartParams,
  ColumnChartSeriesType,
  PlotBand,
} from 'components/columnChart/columnChartTypes';

const PLOT_BAND_OFFSET = 0.3;

export const getEmptyColumnChartData = () =>
  ({
    series: [] as ColumnChartSeriesType[],
  }) as ColumnChartParams;

export const addPlotBands = (itemsList: string[]) => {
  const plotBands = [] as PlotBand[];

  forEach(itemsList, (_, index) => {
    const newItem = {
      color: '#393241',
      from: index - PLOT_BAND_OFFSET,
      to: index + PLOT_BAND_OFFSET,
    } as PlotBand;
    plotBands.push(newItem);
  });

  return plotBands;
};

export const addDataLabelsToChartSeries = () => ({
  enabled: true,
  style: { color: '#f5f5f5' },
});
