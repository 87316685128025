import { DashboardData } from 'mobx/storeTypes';
import { filter, forEach, map, toPairs } from 'lodash-es';
import {
  ColumnChartParams,
  ColumnChartSeriesType,
} from 'components/columnChart/columnChartTypes';
import {
  getEmptyColumnChartData,
  addDataLabelsToChartSeries,
  addPlotBands,
} from 'utils/dataAdapters/chartAdapters';
import { formatDate } from 'utils/dateFormat';
import { dateToColorMappings } from 'utils/dataMappings';

type AllDepartmentPreparedItem = {
  color: string;
  department_id: number;
  department_name: string;
  name: string;
  start_date: string;
  y: number | null;
};

const getAllDepartmentXAxisLabels = (
  data: DashboardData.AllDepartmentInfoList,
  filterByDepartmentName?: string
) => {
  const labels = map(data, (item) => item.department_name);

  return filterByDepartmentName
    ? filter(labels, (label) => label === filterByDepartmentName)
    : labels;
};

export const getAllDepartmentsStatisticsDataAdapted = (
  data: DashboardData.AllDepartmentInfoList,
  filterByDepartmentName?: string
) => {
  if (data.length === 0) {
    return getEmptyColumnChartData();
  }

  const prerapedMap: Record<string, AllDepartmentPreparedItem[]> = {};

  forEach(data, (item) => {
    const department_id = item.department_id;
    const department_name = item.department_name;
    const statistics = item.statistics;

    if (filterByDepartmentName && department_name !== filterByDepartmentName) {
      return;
    }

    forEach(statistics, (statisticsItem) => {
      const start_date = formatDate(statisticsItem.start_date);

      const item = {
        y: statisticsItem.value,
        color: dateToColorMappings[start_date],
        start_date,
        name: 'Средняя оценка',
        department_id,
        department_name,
      };

      if (start_date in prerapedMap) {
        prerapedMap[start_date].push(item);
      } else {
        prerapedMap[start_date] = [];
        prerapedMap[start_date].push(item);
      }
    });
  });

  const entriesFromMap = toPairs(prerapedMap);
  const preparedData: Array<ColumnChartSeriesType> = map(
    entriesFromMap,
    ([label, dataItems]) => {
      const newItem = {
        name: label,
        dataLabels: addDataLabelsToChartSeries(),
        data: dataItems,
      };

      return newItem;
    }
  );

  const xAxisCategories = getAllDepartmentXAxisLabels(
    data,
    filterByDepartmentName
  );

  return {
    series: preparedData,
    axisParams: {
      xAxisCategories,
      plotBands: addPlotBands(xAxisCategories),
      isScrollEnabled: preparedData[0].data.length > 8,
    },
  } as ColumnChartParams;
};
