import React, { useContext, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { UiContext } from 'uiContext';
import CommonCard from 'components/reviewResultsPage/commonCard/commonCard';
import ResponsibilitiesCard from 'components/reviewResultsPage/responsibilitiesCard/responsibilitiesCard';
import ProjectsCard from 'components/reviewResultsPage/projectsCard/projectsCard';
import Top10SelfWordsCard from 'components/reviewResultsPage/top10SelfWordsCard/top10SelfWordsCard';
import CoworkersReviewCard from 'components/reviewResultsPage/coworkersReviewCard/coworkersReviewCard';
import AvgReviewGradeCard from 'components/reviewResultsPage/avgReviewGradeCard/avgReviewGradeCard';
import AchievementsCard from 'components/reviewResultsPage/achievementsCard/achievementsCard';
import FailuresCard from 'components/reviewResultsPage/failuresCard/failuresCard';
import EmployeeReviewsCard from 'components/reviewResultsPage/employeeReviewsCard/employeeReviewsCard';
import AdvicesCard from 'components/reviewResultsPage/advicesCard/advicesCard';
import Top10IntersectionsCard from 'components/reviewResultsPage/top10IntersectionsCard/top10IntersectionsCard';
import Top10CoworkersWordsCard from 'components/reviewResultsPage/top10CoworkersWordsCard/top10CoworkersWordsCard';
import { sxMixins } from 'utils/uiConstants';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { MobxContext } from 'mobx/mobxContext';
import PageHolder from 'components/pageHolder/pageHolder';

export default observer(function EmployeeReviewResults() {
  const theme = useTheme();
  const {
    spacings: { sp },
  } = useContext(UiContext);

  const {
    mobxStore: { authInfoStore },
  } = useContext(MobxContext);

  const { authError, authInfo } = authInfoStore;

  const navigate = useNavigate();

  useEffect(() => {
    if (authError || !authInfo?.token) {
      navigate('/auth_fail');
      return;
    }
  }, [authError, authInfo?.token, navigate]);

  return (
    <PageHolder
      sx={{
        height: 1,
        ...sxMixins.getScrollbarStyle({
          scroollableBg: theme.palette.custom.paperBgColor,
          scrollColor: theme.palette.custom.fontColorNotActive,
        }),
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          paddingLeft: sp.s120,
          paddingRight: sp.s120,
          marginTop: sp.s200,
          marginBottom: sp.s200,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="f70a"
          sx={{
            textTransform: 'uppercase',
            color: theme.palette.custom.white,
          }}
        >
          результаты review
        </Typography>
        <Typography
          variant="f70a"
          sx={{
            color: theme.palette.custom.white,
            textTransform: 'uppercase',
            marginLeft: sp.s30,
          }}
        >
          по сотруднику
        </Typography>
      </Box>

      <Box
        sx={{
          paddingLeft: sp.s120,
          paddingRight: sp.s120,
        }}
      >
        <CommonCard />

        <AvgReviewGradeCard />

        <ResponsibilitiesCard />

        <ProjectsCard />

        <AchievementsCard />

        <FailuresCard />

        <EmployeeReviewsCard />

        <CoworkersReviewCard />

        <AdvicesCard />

        <Top10IntersectionsCard />

        <Top10SelfWordsCard />

        <Top10CoworkersWordsCard />
      </Box>
    </PageHolder>
  );
});
