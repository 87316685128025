import { DashboardData } from 'mobx/storeTypes';
import { forEach, map, toPairs } from 'lodash-es';
import {
  ColumnChartParams,
  ColumnChartSeriesType,
} from 'components/columnChart/columnChartTypes';
import {
  getEmptyColumnChartData,
  addDataLabelsToChartSeries,
  addPlotBands,
} from 'utils/dataAdapters/chartAdapters';
import { formatDate } from 'utils/dateFormat';
import { dateToColorMappings } from 'utils/dataMappings';

type SubDepartmentPreparedItem = {
  color: string;
  employee_id: number;
  employee_name: string;
  name: string;
  start_date: string;
  y: number | null;
};

export const getSubDepartmentXAxisLabels = (
  data: DashboardData.SubDepartmentInfoList
) => {
  return map(data, (item) => item.employee_name);
};

export const getSubDepartmentDataAdapted = (
  data: DashboardData.SubDepartmentInfoList
) => {
  if (data.length === 0) {
    return getEmptyColumnChartData();
  }

  const prerapedMap: Record<string, SubDepartmentPreparedItem[]> = {};

  forEach(data, (item) => {
    const employee_id = item.employee_id;
    const employee_name = item.employee_name;
    const statistics = item.statistics;

    forEach(statistics, (statisticsItem) => {
      const start_date = formatDate(statisticsItem.start_date);

      const item = {
        y: statisticsItem.value,
        color: dateToColorMappings[start_date],
        start_date,
        name: 'Средняя оценка',
        employee_id,
        employee_name,
      };

      if (start_date in prerapedMap) {
        prerapedMap[start_date].push(item);
      } else {
        prerapedMap[start_date] = [];
        prerapedMap[start_date].push(item);
      }
    });
  });

  const entriesFromMap = toPairs(prerapedMap);
  const preparedData: Array<ColumnChartSeriesType> = map(
    entriesFromMap,
    ([label, dataItems]) => {
      const newItem = {
        name: label,
        dataLabels: addDataLabelsToChartSeries(),
        data: dataItems,
      };

      return newItem;
    }
  );

  const xAxisCategories = getSubDepartmentXAxisLabels(data);

  return {
    series: preparedData,
    axisParams: {
      xAxisCategories,
      plotBands: addPlotBands(xAxisCategories),
      isScrollEnabled: preparedData[0].data.length > 8,
    },
  } as ColumnChartParams;
};
