export enum ThemeVariant {
  LIGHT = 0,
  DARK = 1,
}

export namespace DashboardData {
  export type AllDepartmentsInfo = {
    department_id: number;
    department_name: string;
    statistics: StatisticItemInfo[];
  };

  export type AllDepartmentInfoList = AllDepartmentsInfo[];

  export type StatisticItemInfo = {
    by_coworkers: number | null;
    by_lead: number | null;
    by_subordinates: number | null;
    review_period_id: number;
    start_date: string;
    value: number | null;
  };

  export type PreparedItem = {
    y: number | null;
    x?: number;
    color?: string;
    name?: string;
    department_id?: number;
    sub_department_id?: number;
    start_date?: string;
    department_name?: string;
    review_period_id?: number;
    by_coworkers?: number | null;
    by_lead?: number | null;
    by_subordinates?: number | null;
  };

  export type DepartmentInfo = {
    sub_department_id: number;
    sub_department_name: string;
    statistics: StatisticItemInfo[];
  };

  export type DepartmentInfoList = DepartmentInfo[];

  export type SubDepartmentInfo = {
    employee_id: number;
    employee_name: string;
    statistics: StatisticItemInfo[];
  };

  export type SubDepartmentInfoList = SubDepartmentInfo[];

  export type EmployeeInfo = {
    employee_id: number;
    employee_name: string;
    statistics: StatisticItemInfo[];
  };
}

export namespace EmployeeFormData {
  export type Competencies = null;
  export type Coworker = {
    id: number;
    fullname: string;
    username: string;
    count: number;
  };

  export type CrossingCoworkers = {
    coworkers: Coworker[];
    employee_id: number;
    review_period_id: number;
  };

  export type Employee = {
    department: string;
    fullname: string;
    id: number;
    lead: string;
    position: string;
    username: string;
  };

  export type FormProject = {
    name: string;
    description: string;
  };

  export type Form = {
    achievements: string[];
    employee_id: number;
    fails: string[];
    projects: FormProject[];
    responsibilities: string[];
    review_period_id: number;
  };

  export type MarksAnalysis = {
    coworkers_average_mark: number | null;
    employee_id: number;
    lead_average_mark: number | null;
    review_period_id: number;
    subordinates_average_mark: number | null;
    total_average_mark: number | null;
  };

  export type WordsDescriptionObject = {
    value: string;
    count: number;
  };

  export type CoworkersWords = WordsDescriptionObject;
  export type SelfWords = WordsDescriptionObject;

  export type MostUsageWords = {
    coworkers_words: CoworkersWords[];
    employee_id: number;
    review_period_id: number;
    self_words: SelfWords[];
  };

  export type Project = {
    name: string;
    marks: Mark[];
    average: number;
  };

  export type Mark = {
    comment: string;
    fullname: string;
    relationship: string;
    username: string;
    value: number;
  };

  export type Projects = {
    employee_id: number;
    projects: Project[];
    review_period_id: number;
  };

  export type Respondent = {
    advices: string[];
    average_mark: number | null;
    department: string;
    fullname: string;
    id: number;
    position: string;
    relationship: string;
    username: string;
  };

  export type Respondents = Respondent[];

  export type TotalForm = {
    competencies: null;
    crossing_coworkers: CrossingCoworkers;
    employee: Employee;
    form: Form;
    marks_analysis: MarksAnalysis;
    most_usage_words: MostUsageWords;
    projects: Projects;
    respondents: Respondents;
  };

  export type ReviewPeriod = {
    end_date: string;
    id: number;
    start_date: string;
  };
}

export namespace Auth {
  export enum Roles {
    HR = 'HR',
    Employee = 'Employee',
    Newbie = 'Newbie',
    Head = 'Руководитель ИЦ',
    SubDepartmentHead = 'Руководитель отдела',
    DepartmentHead = 'Руководитель центра',
  }

  interface AuthInfoKeys {
    [key: string]: string | number;
  }

  export interface AuthInfo extends AuthInfoKeys {
    token: string;
    user_id: number;
    role: Roles;
    department: string;
    department_id: number;
    fullname: string;
    sub_department_name: string;
    sub_department_id: number;
  }
}
