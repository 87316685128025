export const chartItemValuesMap: Record<string, string> = {
  value: 'Средняя оценка',
  by_lead: 'Руководитель',
  by_coworkers: 'Коллеги',
  by_subordinates: 'Подчинённые',
};

export const monthMappings: Record<string, string> = {
  January: 'Январь',
  Febuary: 'Февраль',
  March: 'Март',
  April: 'Апрель',
  May: 'Май',
  June: 'Июнь',
  July: 'Июль',
  August: 'Август',
  September: 'Сентябрь',
  October: 'Октябрь',
  November: 'Ноябрь',
  December: 'Декабрь',
};

export const monthToSeasonMappings: Record<string, string> = {
  January: 'Зима',
  Febuary: 'Зима',
  March: 'Весна',
  April: 'Весна',
  May: 'Весна',
  June: 'Лето',
  July: 'Лето',
  August: 'Лето',
  September: 'Осень',
  October: 'Осень',
  November: 'Осень',
  December: 'Зима',
};

export const dateToColorMappings: Record<string, string> = {
  'Октябрь 2022': '#3BADEF',
  'Апрель 2023': '#5438DC',
  // 'Январь 2023': '#dd1c9b',
  // 'Август 2023': '#0cc095',
};

export const dateToCssClassMappings: Record<string, string> = {
  'Октябрь 2022': 'turquoise',
  'Апрель 2023': 'blue',
  // 'Январь 2023': 'vinousColor',
  // 'Август 2023': 'green',
};
