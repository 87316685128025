import { EmployeeFormData } from 'mobx/storeTypes';
import { forEach, isNull, map, omit } from 'lodash-es';

type CommonCardListItem = {
  mainText: string;
  upperText: string;
};

type AvgReviewGradeListItem = {
  mainText: string;
  upperText: string;
  score: number | string;
};

type ProjectsCardListItem = {
  mainText: string;
  header: string;
  subHeader: string;
};

type IntersectionListItem = {
  mainText: string;
  score: number;
};

type SelfWordsListItem = {
  mainText: string;
  score: number;
};

type CoworkersWordsListItem = {
  mainText: string;
  score: number;
};

type AdviceListItem = {
  mainText: string;
  header: string;
  commentAuthor?: string;
};

type CoworkersReviewListItem = {
  mainText: string;
  upperText: string;
  score: number;
};

type CoworkersReviewTableListItem = {
  headerLabels: string[];
  rowsData: CoworkersReviewRowDataItem[];
};

type CoworkersReviewRowDataItem = {
  fullName: string;
  score: number;
  comment: string;
};

type CoworkersReviewList = {
  listItemInfo: CoworkersReviewListItem;
  tableItemInfo: CoworkersReviewTableListItem;
};

type LeadAdviceItem = {
  mainText: string;
  upperText: string;
};

type LeadRewiewRowDataItem = {
  projectName: string;
  score: number;
  comment: string;
};

export const adaptEmployeeData = (data: EmployeeFormData.Employee) => {
  const dataWithOmittedValues = omit(data, ['id', 'fullname']);

  const listLabels: Record<string, string> = {
    department: 'Отдел',
    fullname: 'ФИО сотрудника',
    lead: 'Руководитель',
    position: 'Должность',
    username: 'Ник в Telegram',
  };

  const list: CommonCardListItem[] = [];

  forEach(dataWithOmittedValues, (value, key) => {
    const mainText = key === 'username' ? addAtSignToLogin(value) : value;

    const item: CommonCardListItem = {
      mainText,
      upperText: listLabels[key],
    };

    list.push(item);
  });

  return list;
};

const addAtSignToLogin = (value: string) => {
  return `@${value}`;
};

export const adaptAvgReviewGradesData = (
  data: EmployeeFormData.MarksAnalysis
) => {
  const dataWithOmittedValues = omit(data, [
    'total_average_mark',
    'employee_id',
    'review_period_id',
  ]);

  const listLabels: Record<string, { upperText: string; mainText: string }> = {
    coworkers_average_mark: {
      upperText: 'От коллег',
      mainText: 'Средняя оценка от коллег по всем проектам',
    },
    lead_average_mark: {
      upperText: 'От руководителя',
      mainText: 'Средняя оценка от руководителя по всем проектам',
    },
    subordinates_average_mark: {
      upperText: 'От подчиненных',
      mainText: 'Средняя оценка от подчиненных по всем проектам',
    },
  };

  const list: AvgReviewGradeListItem[] = [];

  forEach(dataWithOmittedValues, (value, key) => {
    const item: AvgReviewGradeListItem = {
      mainText: listLabels[key].mainText,
      upperText: listLabels[key].upperText,
      score: isNull(value) ? '-' : value,
    };

    list.push(item);
  });

  return list;
};

export const adaptProjectsData = (data: EmployeeFormData.FormProject[]) => {
  const list = map(data, (item) => ({
    mainText: item.description,
    header: item.name,
    subHeader: 'Описание проекта:',
  })) as ProjectsCardListItem[];

  return list;
};

export const adaptIntersectionsData = (data: EmployeeFormData.Coworker[]) => {
  const list: IntersectionListItem[] = [];

  forEach(data, (item) => {
    const tgNickname = addAtSignToLogin(item.username);

    const listItem: IntersectionListItem = {
      mainText: `${item.fullname} ${tgNickname}`,
      score: item.count,
    };

    list.push(listItem);
  });

  return list;
};

export const adaptSelfWordsData = (data: EmployeeFormData.SelfWords[]) => {
  const list: SelfWordsListItem[] = [];

  forEach(data, ({ value, count }) => {
    const listItem: SelfWordsListItem = {
      mainText: value,
      score: count,
    };

    list.push(listItem);
  });

  return list;
};

export const adaptCoworkersWordsData = (
  data: EmployeeFormData.CoworkersWords[]
) => {
  const list: CoworkersWordsListItem[] = [];

  forEach(data, ({ value, count }) => {
    const listItem: CoworkersWordsListItem = {
      mainText: value,
      score: count,
    };

    list.push(listItem);
  });

  return list;
};

export const adaptAdvicesData = (data: EmployeeFormData.Respondents) => {
  const list: AdviceListItem[] = [];

  forEach(data, (respondent) => {
    const tgNickname = addAtSignToLogin(respondent.username);
    const commentAuthor = `${respondent.fullname} ${tgNickname}`;

    const listItem: AdviceListItem = {
      mainText: respondent.advices.join('. '),
      header: 'Комментарий:',
      commentAuthor,
    };

    list.push(listItem);
  });

  return list;
};

export const adaptCoworkersReviewData = (data: EmployeeFormData.Project[]) => {
  const list: CoworkersReviewList[] = [];

  forEach(data, ({ name, average, marks }) => {
    const itemList = {} as CoworkersReviewList;

    const listItemInfo: CoworkersReviewListItem = {
      mainText: 'Средняя оценка за проект',
      upperText: name,
      score: average,
    };

    const tableItemInfo: CoworkersReviewTableListItem = {
      headerLabels: ['ФИО ОЦЕНИВАЮЩЕГО', 'ОЦЕНКА', 'КОММЕНТАРИЙ'],
      rowsData: [],
    };

    forEach(marks, ({ comment, fullname, value, relationship }) => {
      if (relationship === 'lead') {
        return;
      }

      const rowItem: CoworkersReviewRowDataItem = {
        fullName: fullname,
        score: value,
        comment,
      };

      tableItemInfo.rowsData.push(rowItem);
    });

    itemList.listItemInfo = listItemInfo;
    itemList.tableItemInfo = tableItemInfo;

    list.push(itemList);
  });

  return list;
};

export const adaptLeadAdvice = (data: EmployeeFormData.Respondents) => {
  let adviceItem = {} as LeadAdviceItem;

  forEach(data, ({ relationship, advices }) => {
    if (relationship === 'lead') {
      const item: LeadAdviceItem = {
        mainText: advices.join('. '),
        upperText: 'Советы',
      };

      adviceItem = item;
    }
  });

  return adviceItem;
};

export const adaptLeadReviesTableItemList = (
  data: EmployeeFormData.Project[]
) => {
  const list: LeadRewiewRowDataItem[] = [];

  forEach(data, ({ marks, name }) => {
    forEach(marks, ({ comment, fullname, value, relationship }) => {
      if (relationship === 'lead') {
        const listItem: LeadRewiewRowDataItem = {
          projectName: name,
          score: value,
          comment,
        };

        list.push(listItem);
      }
    });
  });

  return list;
};
