import { DashboardData } from 'mobx/storeTypes';
import { forEach } from 'lodash-es';
import { formatDate } from 'utils/dateFormat';
import { dateToColorMappings } from 'utils/dataMappings';
import { SeriesMap } from './periodFilter';

export const adaptDataToSeriesMap = (
  data: DashboardData.AllDepartmentInfoList
) => {
  if (data.length === 0) {
    return null;
  }

  const seriesMap: SeriesMap = {};

  forEach(data[0].statistics, (item) => {
    const formattedDate = formatDate(item.start_date);

    seriesMap[formattedDate] = {
      seriesName: formattedDate,
      color: dateToColorMappings[formattedDate],
    };
  });

  return seriesMap;
};

export const adaptDataToEnabledSeries = (
  data: DashboardData.AllDepartmentInfoList
) => {
  const enabledSeries: string[] = [];

  forEach(data[0].statistics, (item) => {
    const formattedDate = formatDate(item.start_date);
    enabledSeries.push(formattedDate);
  });

  return enabledSeries;
};
