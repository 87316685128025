import { axiosV1 } from 'api/axios';
import { EmployeeFormData } from 'mobx/storeTypes';

export const employeeFormAPI = {
  getTotalForm: async (employeeId: number, reviewPeriodId: number) => {
    const res = await axiosV1.get<EmployeeFormData.TotalForm>(
      `/report/total_form/${employeeId}`,
      {
        params: {
          review_period_id: reviewPeriodId,
        },
      }
    );

    return res.data;
  },
  getReviewPeriods: async (employeeId: number) => {
    const res = await axiosV1.get<EmployeeFormData.ReviewPeriod[]>(
      '/report/review_periods',
      {
        params: { employee_id: employeeId },
      }
    );

    return res.data;
  },
};
